/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';

import {
  TITLE_MAX_LENGTH,
  MESSAGES,
  SUPPORT_CATEGORY_URL,
} from '../../constants';
import { useSupportBoard } from 'hooks';
import { isNoContent, successSwal } from 'utilities';
import TextEditorNoImage from 'components/ui/TextEditorNoImage';
import SupportTextEditor from 'components/ui/SupportTextEditor';
import { BoardAppendixFile, ValidationErrorMsg } from 'components/common';

const SupportBoardWrite = () => {
  const location = useLocation();
  const { boardId } = location.state;

  const history = useHistory();

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [boardName, setBoardName] = useState('카테고리를 선택해주세요.');
  const [categoryId, setCategoryId] = useState(0);
  const [textValue, setTextValue] = useState('');
  const [files, setFiles] = useState([]);
  const [, setDeleteAttachments] = useState([]);
  const [, setDeleteImgs] = useState([]);
  const [imageNames, setImagesNames] = useState([]);
  const [imgList, setImgList] = useState([]);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const { submitPost, boardSelect } = useSupportBoard({});

  const submit = async (form) => {
    if (isNoContent(textValue)) {
      setError('content', {
        type: 'required',
      });
    }
    if (!categoryId) {
      swal('카테고리를 선택해주세요.', {
        icon: 'error',
      });
    }

    const body = {
      boardId: categoryId,
      categoryId,
      title: form.title,
      content: textValue,
      images: imageNames,
    };

    const formData = new FormData();

    formData.append(
      'data',
      new Blob([JSON.stringify(body)], {
        type: 'application/json',
      })
    );
    if (files) {
      formData.append('file', files[0]);
    }

    const response = await submitPost.mutateAsync({ formData });
    if (response) {
      history.push(SUPPORT_CATEGORY_URL[categoryId - 1]);
      await successSwal({ text: MESSAGES.POST_SUBMIT_SUCCESS });
    }
  };

  const handleChangeFile = (fileList) => {
    if ([...fileList].length > 1) {
      swal({ text: MESSAGES.FILE_MAXIMUM_COUNT_1, icon: 'error' });
      return;
    }

    setFiles([...fileList]);
  };

  const handleDeleteFile = () => {
    setFiles([]);
  };

  useEffect(() => {
    if (!boardId) return;

    const selectedBoardName = boardSelect.data?.find(
      (e) => e.id === +boardId
    ).categoryName;

    setCategoryId(boardId);
    setBoardName(selectedBoardName);
  }, [boardSelect, location.state]);

  return (
    <div className="h-80">
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <div className="d-sm-flex">
                <ButtonGroup>
                  <DropdownButton
                    variant="dark"
                    title={boardName}
                    id="bg-vertical-dropdown-3"
                    disabled
                    style={{ cursor: 'not-allowed' }}
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() =>
                        handleCategorySelect({
                          categoryId: 0,
                          categoryName: '카테고리를 선택해주세요.',
                        })
                      }
                      className="fs-12"
                    >
                      전체 카테고리
                    </Dropdown.Item>
                    {boardSelect.data &&
                      boardSelect.data.map((sub, idx) => (
                        <Dropdown.Item
                          key={idx}
                          eventKey={idx + 1}
                          className="fs-12"
                          onClick={() => handleCategorySelect(sub)}
                        >
                          {sub.categoryName}
                        </Dropdown.Item>
                      ))}
                  </DropdownButton>
                </ButtonGroup>
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="basic-form">
                    <form onSubmit={handleSubmit(submit)}>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <input
                            type="text"
                            placeholder="제목을 입력하세요."
                            className="form-control"
                            name="title"
                            {...register('title', {
                              required: true,
                              maxLength: TITLE_MAX_LENGTH,
                            })}
                          />
                          {errors.title?.type === 'required' && (
                            <ValidationErrorMsg text="제목을 입력해 주세요." />
                          )}
                          {errors.title?.type === 'maxLength' && (
                            <ValidationErrorMsg text="80자 이하로 입력해주세요." />
                          )}
                        </div>

                        <div className="form-group col-md-12">
                          <div className="default-tab">
                            {boardName === '자료실' ? (
                              <SupportTextEditor
                                htmlStr={textValue}
                                setHtmlStr={setTextValue}
                                isEdit={false}
                                setImageNames={setImagesNames}
                                setIsImageLoading={setIsImageLoading}
                                setDeleteImgs={setDeleteImgs}
                                imgList={imgList}
                              />
                            ) : (
                              <TextEditorNoImage
                                htmlStr={textValue}
                                setHtmlStr={setTextValue}
                                style={{ borderRadius: '15px' }}
                              />
                            )}
                            {errors.content?.type === 'required' && (
                              <ValidationErrorMsg text="내용을 입력해 주세요." />
                            )}
                          </div>
                        </div>
                      </div>

                      {boardName === '자료실' ? (
                        <div className="form-row mt-3">
                          <div className="form-group col-md-12">
                            <h5 className="mb-2">
                              <i className="fa fa-paperclip" />
                              첨부파일
                            </h5>
                            <BoardAppendixFile
                              onChange={handleChangeFile}
                              onDelete={handleDeleteFile}
                              originalFileNames={files}
                            />
                          </div>
                        </div>
                      ) : null}

                      <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          게시글 등록
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportBoardWrite;
