import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useGlobalFilter,
  useFilters,
  usePagination,
  useTable,
} from 'react-table';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { COLUMNS } from '../../components/Board/Columns';
import {
  useDebounce,
  useGetQueryString,
  useSetParameterOnQuery,
  useSupportBoard,
} from 'hooks';
import { confirmSwal, successSwal } from 'utilities';
import { MESSAGES, BADGE_COLOR } from '../../constants';
import {
  BoardWriteButton,
  SearchInput,
  Pagination,
  FileIcon,
} from 'components/common';
import { Wrapper, CardHeader, CardBody } from 'components/layouts/common';
import '../../components/Board/filtering.css';

const SupportLibrary = () => {
  const queryValues = useGetQueryString();
  const history = useHistory();

  /* 자료실 Id : 1 */
  const [boardId] = useState(1);
  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
    }
  }, [queryValues]);

  const { boardList, deleteSupportPost } = useSupportBoard({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    pageSize,
    board: 1,
  });
  const { data } = boardList || {};

  //Table Setting
  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  const handleDelete = async (row) => {
    const confirm = await confirmSwal({
      title: '게시글을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      const { status } = await deleteSupportPost.mutateAsync({
        id: row.original.id,
      });
      if (status === 204) {
        await successSwal({ text: MESSAGES.POST_DELETE_SUCCESS });
      }
    }
  };

  const handleGoDetail = (id) => history.push(`/support-board/${id}`);

  return (
    <Wrapper>
      <CardHeader justifyContent="flex-end">
        <div>
          <SearchInput value={keyword} setValue={setKeyword} />
        </div>
      </CardHeader>

      <CardBody>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table filtering-table table-responsive-lg"
          >
            <thead className="first-child-short">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      let content;
                      switch (i) {
                        case 0:
                          content = (
                            <td {...cell.getCellProps()} key={i}>
                              <div
                                className={`badge rounded-pill bg-${
                                  BADGE_COLOR[cell.row.original.boardId - 1]
                                } text-white`}
                                style={{ opacity: '0.7' }}
                              >
                                {cell.row.original.boardName}
                              </div>
                            </td>
                          );
                          break;
                        case 1:
                          content = (
                            <td
                              {...cell.getCellProps()}
                              key={i}
                              onClick={() =>
                                handleGoDetail(cell.row.original.id)
                              }
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <Title>{cell.row.original.title}</Title>
                              {cell.row.original.isAttached && <FileIcon />}
                            </td>
                          );
                          break;
                        case 4:
                          content = (
                            <td {...cell.getCellProps()} key={i}>
                              <ReactTooltip />
                              <i
                                className="far fa-trash-alt more-btn-icon-box mr-1"
                                data-tip="삭제하기"
                                onClick={() => handleDelete(row)}
                              />
                            </td>
                          );
                          break;
                        default:
                          content = (
                            <td {...cell.getCellProps()} key={i}>
                              {cell.render('Cell')}
                            </td>
                          );
                          break;
                      }
                      return content;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page.length === 0 ? (
            <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
              검색 결과가 없습니다.
            </div>
          ) : (
            ''
          )}

          <BoardWriteButton
            to={{
              pathname: '/support-board-write',
              state: { boardId: boardId },
            }}
          />

          {page.length !== 0 && (
            <Pagination
              page={page}
              pageNum={pageNum}
              setPageNum={setPageNum}
              data={data || {}}
              maxPostPage={data?.pages}
            />
          )}
        </div>
      </CardBody>
    </Wrapper>
  );
};

const Title = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 30px);
  vertical-align: middle;
`;

export default SupportLibrary;
