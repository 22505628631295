import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { Viewer } from '@toast-ui/react-editor';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import '@toast-ui/editor/dist/toastui-editor.css';

import { useDownload, useSupportBoard } from 'hooks';
import { confirmSwal, successSwal } from 'utilities';
import { MESSAGES } from '../../constants';
import { UserSection } from 'components/common';
import SupportBoardComment from 'components/Board/SupportBoardComment';
import { CardBody, Wrapper } from 'components/layouts/common';

const SupportBoardDetail = () => {
  const { id } = useParams();
  const history = useHistory();

  const {
    boardDetail,
    boardReply,
    boardFiles,
    deleteSupportPost,
    changeReplyReadStatus,
  } = useSupportBoard({ id });

  const { data } = boardDetail || {};
  const { data: files } = boardFiles || {};
  const { data: replies } = boardReply || {};

  const [imageUrl, setImageUrl] = useState('');

  const { refetch, isLoading } = useDownload(
    `${process.env.REACT_APP_IP}/admin/posts/${id}/attachments/${
      !!files?.length && files[0].id
    }`
  );

  const handleDownload = async () => {
    if (isLoading) return;
    await refetch();
  };

  const handleDelete = async () => {
    const confirm = await confirmSwal({
      title: '게시글을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      const { status } = await deleteSupportPost.mutateAsync({ id });
      if (status === 204) {
        history.goBack();
        await successSwal({ text: MESSAGES.POST_DELETE_SUCCESS });
      }
    }
  };

  const handleBack = () => history.goBack();
  const handleGoToEdit = () => history.push(`/support-board-edit/${id}`);

  const renderedContent = useMemo(() => {
    return data?.content.replace(/temp/g, `${data?.id}`);
  }, [data?.content, data?.imageName, imageUrl, setImageUrl]);

  useEffect(() => {
    if (data && data.boardId === 3) {
      (async () => {
        await changeReplyReadStatus.mutateAsync({ postId: id });
      })();
    }
  }, [data]);

  return (
    <Fragment>
      <Wrapper>
        <CardBody>
          <div className="toolbar mb-2" role="toolbar">
            <div className="bootstrap-badge">
              <Badge variant="secondary badge-sm mr-2">
                Board : {data?.boardName}
              </Badge>
              <Badge variant="light badge-sm mr-2">
                Category : {data?.categoryName}
              </Badge>
              <Badge variant="dark badge-sm">Type : {data?.categoryType}</Badge>
            </div>
          </div>
          <div className="read-content">
            <div className="media pt-3 d-sm-flex d-block justify-content-between">
              {data && (
                <UserSection
                  dataA={
                    data?.boardId === 3
                      ? `${data?.author}(${data?.phone})`
                      : data?.author
                  }
                  dataB={data?.userId}
                  dataC={(data?.createdAt || '').replace('T', ' ').slice(0, 16)}
                />
              )}
              <div className="clearfix mb-3 flex-1" style={{ display: 'flex' }}>
                <ReactTooltip place="bottom" />
                <div
                  data-tip="뒤로가기"
                  onClick={handleBack}
                  className="btn btn-primary px-3 light"
                >
                  <i className="fa fa-reply" />
                </div>

                {/* 공통 자료실, FAQ만 관리자가 게시글 수정 가능. */}
                {[1, 2].includes(data?.boardId) && (
                  <div
                    data-tip="수정하기"
                    onClick={handleGoToEdit}
                    className="btn btn-primary px-3 light ml-2"
                  >
                    <i className="fa-regular fa-pen-to-square" />
                  </div>
                )}

                <div
                  data-tip="삭제하기"
                  onClick={handleDelete}
                  className="btn btn-primary px-3 light ml-2"
                >
                  <i className="fa fa-trash" />
                </div>
              </div>
            </div>

            <hr />

            <div className="media mb-3 mt-4">
              <div className="media-body">
                <span className="pull-right">{data?.regDate}</span>
                <h3 className="my-1 text-primary">{data?.title}</h3>
              </div>
            </div>

            <div className="read-content-body">
              {data && (
                <ImageBox>
                  <Viewer initialValue={renderedContent} />
                </ImageBox>
              )}
            </div>

            <hr />

            <div className="read-content-attachment">
              <h6>
                <i className="fa fa-download mb-2"></i>
                Files
                <Badge variant="primary badge-sm ml-2 mr-2">
                  {files?.length}
                </Badge>
              </h6>
              <div className="row attachment">
                {files?.length > 0 ? (
                  files.map((file) => (
                    <FileItem
                      onClick={handleDownload}
                      className="col-auto"
                      key={file.id}
                    >
                      {file.originalFileName}
                    </FileItem>
                  ))
                ) : (
                  <div className="col-auto">등록된 자료 파일이 없습니다.</div>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Wrapper>

      {/* 댓글 컴포넌트 */}
      <SupportBoardComment replyList={replies} />
    </Fragment>
  );
};

const ImageBox = styled.div`
  width: 100%;

  .ql-size-large {
    font-size: 1.3rem;
  }

  .ql-size-huge {
    font-size: 1.8rem;
  }
`;

const FileItem = styled.div`
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #0c85d0;
    text-decoration: underline;
  }
`;

export default SupportBoardDetail;
