import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';

import { useSupportBoard } from 'hooks';
import { TITLE_MAX_LENGTH } from 'constants/classBoard';
import { isNoContent } from 'utilities/common';
import TextEditorNoImage from '../../components/ui/TextEditorNoImage';
import SupportTextEditor from 'components/ui/SupportTextEditor';
import { confirmSwal, successSwal } from 'utilities';
import { MESSAGES, SUPPORT_CATEGORY_URL } from 'constants';
import {
  BoardAppendixFile,
  ValidationErrorMsg,
  UserSection,
} from '../../components/common';
import { CardBody, Wrapper } from '../../components/layouts';

export const SupportBoardEdit = () => {
  const { id } = useParams();
  let history = useHistory();

  const boardType = {
    1: 'support-library',
    2: 'faq',
  };

  const {
    register,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const { boardDetail, boardFiles, deleteSupportPost, editPost } =
    useSupportBoard({
      id,
    });
  const { data: detailData } = boardDetail || {};
  const { data: attachments } = boardFiles || {};

  const [textValue, setTextValue] = useState('');
  const [boardId, setBoardId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [fileIdToDelete, setFileIdToDelete] = useState();
  const [deleteImgs, setDeleteImgs] = useState([]);
  const [imagesNames, setImagesNames] = useState([]);
  const [imgList, setImgList] = useState([]);

  const [, setIsImageLoading] = useState(false);

  const [file, setFile] = useState([]);

  const handleDelete = async () => {
    const confirm = await swal({
      title: '게시글을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
      className: 'mySwal',
    });

    if (confirm) {
      const { status } = await deleteSupportPost.mutateAsync({
        id,
      });
      if (status === 204) {
        history.push(SUPPORT_CATEGORY_URL[categoryId - 1]);
        await successSwal({ text: MESSAGES.POST_DELETE_SUCCESS });
      }
    }
  };

  const submit = async (form) => {
    if (isNoContent(textValue)) {
      setError('content', {
        type: 'required',
      });
    }

    const body = {
      boardId,
      categoryId,
      title: form.title,
      content: textValue,
      images: imagesNames,
      deleteImages: deleteImgs,
      fileIdToDelete,
    };

    const frm = new FormData();

    frm.append(
      'data',
      new Blob([JSON.stringify(body)], {
        type: 'application/json',
      })
    );
    if (file) {
      frm.append('file', file[0]);
      frm.append('fileIdToDelete', fileIdToDelete);
    }

    const confirm = await confirmSwal({
      title: '게시글을 수정합니다.',
      text: '수정을 진행하시겠습니까?',
    });
    if (confirm) {
      const { status } = await editPost.mutateAsync({ id, formData: frm });
      if (status === 204) {
        history.push(`/${boardType[detailData.categoryId]}`);
        await successSwal({ text: MESSAGES.POST_EDIT_SUCCESS });
      }
    }
  };

  const handleChangeFile = (fileList) => {
    if ([...fileList].length > 5) {
      swal({ text: MESSAGES.FILE_MAXIMUM_COUNT_5, icon: 'error' });
      return;
    }

    setFile((prev) => {
      if ([...prev, ...fileList].length > 5) {
        swal({ text: MESSAGES.FILE_MAXIMUM_COUNT_5, icon: 'error' });
        return [...prev];
      }

      return [...prev, ...fileList];
    });
  };

  const handleDeleteFile = (index, fileId) => {
    setFile((prev) => {
      const newList = [...prev];
      newList.splice(index, 1);
      return newList;
    });
    setFileIdToDelete(fileId);
  };

  useEffect(() => {
    if (!detailData) return;

    function updateImagePaths(htmlString) {
      return htmlString.replace(/temp/g, `${detailData?.id}`);
    }

    const parsingValue = updateImagePaths(detailData?.content);

    setValue('title', detailData.title);
    setTextValue(parsingValue);
    setCategoryId(detailData.categoryId);
    setBoardId(detailData.boardId);
    setFile(attachments);
    setImgList(detailData.images);
  }, [detailData]);

  return (
    <Wrapper>
      <CardBody className="card-body">
        <div className="right-box-padding">
          <div className="toolbar mb-2" role="toolbar">
            <div className="bootstrap-badge">
              <Badge variant="secondary badge-sm mr-2">
                BoardType : {detailData?.boardName}
              </Badge>
              <Badge variant="light badge-sm mr-2">
                Category : {detailData?.categoryName}
              </Badge>
              <Badge variant="dark badge-sm">{detailData?.categoryType}</Badge>
            </div>
          </div>

          <div className="read-content">
            <div className="media pt-3 d-sm-flex d-block justify-content-between">
              <UserSection
                dataA={detailData?.author}
                dataB={detailData?.userId}
              />
              <div className="clearfix mb-3">
                <Link
                  to={{
                    pathname: '#',
                    state: {},
                  }}
                  onClick={() => history.goBack()}
                  className="btn btn-primary px-3 light"
                >
                  <i className="fa fa-reply"></i>
                </Link>
                <div
                  onClick={handleDelete}
                  className="btn btn-primary px-3 light ml-2"
                >
                  <i className="fa fa-trash"></i>
                </div>
              </div>
            </div>

            <div className="read-content-body mt-5">
              <div className="basic-form">
                <form onSubmit={handleSubmit(submit)}>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        placeholder="제목을 입력하세요."
                        {...register('title', {
                          required: true,
                          maxLength: TITLE_MAX_LENGTH,
                        })}
                      />
                      {errors.title?.type === 'required' && (
                        <ValidationErrorMsg text="제목을 입력해 주세요." />
                      )}
                      {errors.title?.type === 'maxLength' && (
                        <ValidationErrorMsg text="80자 이하로 입력해주세요." />
                      )}
                    </div>

                    <div className="form-group col-md-12">
                      <div className="default-tab">
                        {detailData?.categoryName === '자료실' ? (
                          <SupportTextEditor
                            htmlStr={textValue}
                            setHtmlStr={setTextValue}
                            isEdit={1}
                            setImageNames={setImagesNames}
                            setIsImageLoading={setIsImageLoading}
                            setDeleteImgs={setDeleteImgs}
                            imgList={imgList}
                          />
                        ) : (
                          <TextEditorNoImage
                            htmlStr={textValue}
                            setHtmlStr={setTextValue}
                            style={{ borderRadius: '15px' }}
                          />
                        )}
                        {errors.content?.type === 'required' && (
                          <ValidationErrorMsg text="내용을 입력해 주세요." />
                        )}
                      </div>
                    </div>
                  </div>

                  {detailData?.categoryName === '자료실' ? (
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <h5 className="mb-2">
                          <i className="fa fa-paperclip"></i> 첨부파일
                        </h5>
                        <BoardAppendixFile
                          onChange={handleChangeFile}
                          onDelete={handleDeleteFile}
                          originalFileNames={file}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <hr />
                  <div className="d-flex justify-content-end mt-5">
                    <div
                      onClick={() => history.goBack()}
                      className="btn btn-dark mr-2"
                    >
                      취소
                    </div>
                    <button type="submit" className="btn btn-primary">
                      게시글 수정
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};

export default SupportBoardEdit;
