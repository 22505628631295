import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

import { useClassBoard } from 'hooks';
import { CLASS_CATEGORY_URL, TITLE_MAX_LENGTH } from 'constants/classBoard';
import ClassTextEditor from 'components/ui/ClassTextEditor';
import { successSwal, isNoContent } from 'utilities';
import { MESSAGES } from 'constants';
import { ValidationErrorMsg, BoardAppendixFile } from 'components/common';
import { CardBody, Wrapper } from 'components/layouts/common';

const ClassBoardEdit = () => {
  const { id } = useParams();
  let history = useHistory();
  const {
    register,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [textValue, setTextValue] = useState('');
  const [deleteAttachments, setDeleteAttachments] = useState([]);
  const [deleteImgs, setDeleteImgs] = useState([]);
  const [imagesNames, setImagesNames] = useState([]);
  const [imgList] = useState([]);

  const [, setIsImageLoading] = useState(false);

  const [files, setFiles] = useState([]);

  const { boardDetail, boardAttachments, editPost } = useClassBoard({
    postId: id,
  });
  const { data: fileData } = boardAttachments || {};
  const { data: detailData } = boardDetail || {};

  const submit = async (form) => {
    if (isNoContent(textValue)) {
      setError('content', {
        type: 'required',
      });
    }

    const body = {
      lcPostCategoryId: detailData?.lcPostCategoryId,
      title: form.title,
      content: textValue,
      classId: detailData?.classId,
      images: imagesNames,
      deleteImages: deleteImgs,
      deleteAttachments,
    };

    const formData = new FormData();
    formData.append(
      'data',
      new Blob([JSON.stringify(body)], {
        type: 'application/json',
      })
    );
    if (files.length > 0) {
      files.forEach((file) => {
        formData.append('files', file);
      });
    }

    const { status } = await editPost.mutateAsync({ formData });
    if (status === 204) {
      history.push(CLASS_CATEGORY_URL[detailData?.lcPostCategoryId - 1]);
      await successSwal({ text: MESSAGES.POST_EDIT_SUCCESS });
    }
  };

  const handleChangeFile = (fileList) => {
    if ([...fileList].length > 5) {
      swal({ text: '파일은 최대 5개까지 업로드 가능합니다.', icon: 'error' });
      return;
    }

    setFiles((prev) => {
      if ([...prev, ...fileList].length > 5) {
        swal({ text: '파일은 최대 5개까지 업로드 가능합니다.', icon: 'error' });
        return [...prev];
      }

      return [...prev, ...fileList];
    });
  };

  const handleDeleteFile = (index, fileName) => {
    setFiles((prev) => {
      const newList = [...prev];
      newList.splice(index, 1);
      return newList;
    });
    setDeleteAttachments((prev) => {
      if (fileData?.find((e) => e.newFileName === fileName)) {
        return [...prev, fileName];
      } else {
        return [...prev];
      }
    });
  };

  useEffect(() => {
    if (!detailData) return;

    function updateImagePaths(htmlString) {
      return htmlString.replace(/temp/g, `${detailData?.classId}/${id}`);
    }

    const parsingValue = updateImagePaths(detailData?.content);

    setValue('title', detailData?.title);
    setTextValue(parsingValue);
    setFiles(fileData);
  }, [detailData]);

  return (
    <Wrapper>
      <CardBody>
        <div className="toolbar mb-4" role="toolbar">
          <div className="bootstrap-badge">
            <Badge variant="secondary badge-sm mr-2">
              기수 : {detailData?.className}
            </Badge>

            <Badge variant="light badge-sm mr-2">
              카테고리 : {detailData?.lcPostCategoryName}
            </Badge>
          </div>
        </div>

        <form onSubmit={handleSubmit(submit)}>
          <div className="form-row">
            <div className="form-group col-md-12">
              <input
                type="text"
                placeholder="제목을 입력하세요."
                className="form-control"
                name="title"
                {...register('title', {
                  required: true,
                  maxLength: TITLE_MAX_LENGTH,
                })}
              />
              {errors.title?.type === 'required' && (
                <ValidationErrorMsg text="제목을 입력해주세요." />
              )}
              {errors.title?.type === 'maxLength' && (
                <ValidationErrorMsg text="80자 이하로 입력해주세요." />
              )}
            </div>
            <div className="form-group col-md-12">
              <div className="default-tab">
                <ClassTextEditor
                  htmlStr={textValue}
                  setHtmlStr={setTextValue}
                  isEdit={!!id}
                  setImageNames={setImagesNames}
                  setIsImageLoading={setIsImageLoading}
                  setDeleteImgs={setDeleteImgs}
                  imgList={detailData.images || []}
                />
                {errors.content?.type === 'required' && (
                  <ValidationErrorMsg text="내용을 입력해 주세요." />
                )}
              </div>
            </div>
          </div>

          <div className="form-row mt-3">
            <div className="form-group col-md-12">
              <h5 className="mb-2">
                <i className="fa fa-paperclip" />
                첨부파일
              </h5>
              <BoardAppendixFile
                onChange={handleChangeFile}
                onDelete={handleDeleteFile}
                originalFileNames={files || []}
                multiple
              />
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              게시글 수정
            </button>
          </div>
        </form>
      </CardBody>
    </Wrapper>
  );
};

export default ClassBoardEdit;
